<template>
  <div class="background">
    <div class="container">
      <div class="alert" v-if="errorMsg.length > 0">
        <h1>Alert</h1>
        <h3>Wrong password</h3>
      </div>

      <form @submit.prevent="submitPassword($event)" class="form shadow">
        <h1>Vpišite geslo</h1>
        <input
          type="password"
          placeholder="Password"
          v-model="model.password"
          required
        />        

        <input type="submit" value="Submit" />
      </form>
    </div>
  </div>
</template>

<script>
//Store
import { mapStores } from "pinia";
import { useUserStore } from "@/stores/UserStore";

export default {
  data() {
    return {
      model: {
        password: "",
      },
      errorMsg: "",
    }
  },
  methods: {
    async submitPassword(e) {
      e.preventDefault()

      let res = await this.$api.statisticsLogin(this.model, this.userStore.accessToken)

      if (res.success !== true) {
        this.errorMsg = res.msg
      } else {
        this.$store.setAllowStats(true)
        this.$router.push({ path: "/eFAN-Statistics" })
      }
      this.errorMsg = "Password was wrong!!"
    },
  },
  computed: {
    ...mapStores(useUserStore),
  }
}
</script>

<style lang="scss" scoped>
.background {
  position: relative;
  top: 0;
  left: 0;
  height: 100vh;
  background: linear-gradient(120deg, #fd8e00 0%, #fd6631 100%);
}

.container {
  width: 90%;
  height: 100%;
  max-width: 500px;
  @include center;

  .form {
    position: relative;
    top: 100px;
    @include center;
    padding: 5%;
    margin-bottom: 15px;

    @include box-shadow;
    background-color: #242326;
    text-align: center;
    border: none;
    border-radius: 15px;

    h1 {
      @extend .noselect;
      margin-top: 0;
      margin-bottom: 20px;

      color: #f1f1fe;
      font-size: 1.3em;
    }

    .disclaimer-text {
      color: #fff;
    }

    // Inputs
    input {
      display: block;
      box-sizing: content-box;
      width: 90%;
      max-width: 90%;
      @include center;
      margin-top: 15px;

      border: none;
      border-radius: 15px;
      background-color: #f1f1fe;
      color: #090909;

      @include placeholder {
        color: #8b8a92;
        font-size: 1.1em;
      }
      animation: fadeIn 0.3s, popup 0.4s;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="url"] {
      padding: 15px;

      &:focus {
        background-color: #f1f1fe;
      }
    }
    input[type="submit"] {
      @extend .pointer;
      @extend .noselect;
      @include gradient;

      width: 30%;
      min-width: 6em;
      padding: 15px;

      color: #ffffff;
      font-size: 1em;

      &:hover {
        @include popup(1.04);
      }
    }
  }

  // Controls
  a,
  button {
    @extend .pointer;
    @extend .noselect;

    display: block;
    @include center;
    padding: 10px;
    border: none;
    border-radius: 3px;
    color: #f1f1fe;

    &:hover {
      color: #f1f1fe;
    }
  }

  .alert {
    @include center;
    @include box-shadow;

    animation: fadeIn 0.7s, popup 0.7s, rotate 0.4s;

    position: fixed;
    z-index: 100;
    top: 10%;
    right: 10%;

    background-color: var(--orange);
    border-radius: 15px;
    padding: 10px;

    h1 {
      color: #242326;
      font-size: 1.5em;
    }
    h3 {
      color: #242326;
      font-size: 1.2em;
    }
  }
}
</style>
